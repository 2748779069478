import {STRINGS} from '@app/common/constants';
import {strings} from '@app/common/strings';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {
  Daily_Expense,
  Misc_Expense,
} from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {
  approveExpenseStatusCreator,
  fetchExpenseApproveStatusCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import theme from '@app/themes';
import {CircularProgress} from '@material-ui/core';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';

import {useDispatch, useSelector} from 'react-redux';

const styles = {
  dialogtagline: {
    color: 'text.secondary',
  },

  approveDialogActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  approveDialogContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  card: {
    width: 'auto',
    backgroundColor: theme.colors.grey[2500],
    height: 55,
    borderRadius: 10,
    padding: 10,
  },
  dialogPaperBox: {
    borderRadius: 30,
    boxShadow: '20px',
    width: '30%',
    padding: 20,
  },
};

export const util: any = {
  handleCheckBoxChange: null,
  confirmExpense: null,
  handleApproveClose: null,
};

export const ApproveExpenseModal = () => {
  const dispatch = useDispatch();
  // selected row from staff dropdown
  const adminExpenseSelectedRow = useSelector(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );
  const footer = useSelector(approveExpenseStateSelector.getFooterDatas());
  //   daily api count data
  const dailys = useSelector(approveExpenseStateSelector.getDailyCountData());
  const unlockDeduction = useSelector(
    approveExpenseStateSelector.getUnlockDeduction(),
  );
  const isUnlockDeductionLoading = useSelector(
    approveExpenseStateSelector.getUnlockDeductionLoading(),
  );

  const isModalOpen = useSelector(
    approveExpenseStateSelector.getIsAprroveExpenseModalOpen(),
  );

  //   checkbox
  const isCheckBoxEnabled = useSelector(
    approveExpenseStateSelector.getApproveExpenseModalCheckbox(),
  );

  //   close modal and edeselect checkbox
  const handleApproveClose = () => {
    dispatch(approveExpenseStateActions.setOpenApproveExpenseModal(false));
    dispatch(approveExpenseStateActions.setApproveExpenseModalCheckbox(false));
    dispatch(approveExpenseStateActions.resetUnlockDeduction());
  };

  // select/de-select checkbox
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      approveExpenseStateActions.setApproveExpenseModalCheckbox(
        event?.target?.checked,
      ),
    );

  // daily data
  const expenseApprovalsDaily: Daily_Expense[] = useSelector(
    approveExpenseStateSelector.getDailyExpenseApprovalsData(),
  );

  //   misc data
  const expenseApprovalsMisc: Misc_Expense[] = useSelector(
    approveExpenseStateSelector.getMiscExpenseApprovalsData(),
  );

  const confirmExpense = () => {
    let dailyData = expenseApprovalsDaily?.map(day => {
      return {
        expenseDetailId: day?.expenseDetailId,
        approvedDistance:
          day?.adminPassedDistance == null ? 0 : day?.adminPassedDistance,
        approvedTAAmount: day?.adminPassedTA,
        taChangeReason: day?.adminChangeReasonTA,
        approvedHQType: day?.hqTypeAdmin,
        approvedDAAmount: day?.adminPassedDA,
        daChangeReason: day?.adminChangeReasonDA,
      };
    });
    let miscData = expenseApprovalsMisc?.map(misc => {
      return {
        expMiscellaneousHistoryId: misc?.expMiscHistoyId,
        adminApprovedAmount: misc?.amountAdminPassed,
        adminApprovedRemark: misc?.adminChangeReason,
      };
    });
    let moieVal = expenseApprovalsMisc?.reduce((acc: any, curr: any) => {
      if (curr?.expenseType?.match('MOIE')) {
        return Number(acc) + Number(curr?.amountAdminPassed);
      } else {
        return acc;
      }
    }, 0);

    let requestBody = {
      expenseHistoryId: adminExpenseSelectedRow?.expenseHistoryId,
      adminApprovedTaAmount: footer?.adminApprovedTaAmount
        ? footer?.adminApprovedTaAmount
        : adminExpenseSelectedRow?.adminApprovedTaAmount,
      adminApprovedDaAmount: footer?.adminApprovedDaAmount
        ? footer?.adminApprovedDaAmount
        : adminExpenseSelectedRow?.adminApprovedDaAmount,
      adminApprovedMiscAmount: footer?.adminApprovedMiscAmount
        ? footer?.adminApprovedMiscAmount
        : adminExpenseSelectedRow?.adminApprovedMiscAmount,
      adminApprovedDistance: dailys?.adminPassedDistance
        ? dailys?.adminPassedDistance
        : adminExpenseSelectedRow?.totalDistance,
      AdminapprovedMoieAmount: moieVal,
      AdminApprovedPenaltyAmount: unlockDeduction?.penaltyAmount,
      adminComment: '',
      detailItems: dailyData,
      miscItems: miscData,
    };
    dispatch(fetchExpenseApproveStatusCreator(requestBody));
    const request = {
      month: adminExpenseSelectedRow?.expenseMonth,
      year: adminExpenseSelectedRow?.expenseYear,
      staffPositionId: adminExpenseSelectedRow?.staffPositionId,
      userId: adminExpenseSelectedRow?.staffUserId,
    };
    dispatch(approveExpenseStatusCreator(request));
  };

  util.handleCheckBoxChange = handleCheckBoxChange;
  util.confirmExpense = confirmExpense;
  util.handleApproveClose = handleApproveClose;

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleApproveClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: styles.dialogPaperBox,
      }}>
      <DialogTitle id="alert-dialog-title">
        {`${STRINGS.moduleSpecificUtilities.approveExpense.screen2.approveModal.approveExpensesQuestion}`}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{fontSize: 11}} color="text.secondary">
          {' '}
          {strings.moduleSpecificUtilities.aboutToApprove}
          <Typography display="inline" sx={{fontSize: 12}} color="primary">
            {' '}
            {adminExpenseSelectedRow?.staffName}{' '}
          </Typography>
          <br /> {strings.moduleSpecificUtilities.expenseOfMonth}{' '}
          <Typography sx={{fontSize: 12}} display="inline" color="primary">
            {
              monthMock()?.find(
                month => month?.key === adminExpenseSelectedRow?.expenseMonth,
              )?.value
            }
            {' , '}
            {adminExpenseSelectedRow?.expenseYear}
          </Typography>
        </Typography>
        <Box sx={{...styles.approveDialogContent, mb: 1, mt: 1}}>
          <div style={styles.card}>
            <Typography sx={{fontSize: 8}} color="primary">
              {strings.moduleSpecificUtilities.daApproved}
            </Typography>
            <Typography sx={{fontSize: 16}} color="text.secondary">
              ₹
              {footer?.adminApprovedDaAmount
                ? footer?.adminApprovedDaAmount?.toFixed(0)
                : adminExpenseSelectedRow?.adminApprovedDaAmount?.toFixed(0)}
            </Typography>
          </div>
          <div style={styles.card}>
            <Typography sx={{fontSize: 8}} color="primary">
              {strings.moduleSpecificUtilities.taApproved}
            </Typography>
            <Typography sx={{fontSize: 16}} color="text.secondary">
              ₹
              {footer?.adminApprovedTaAmount
                ? footer?.adminApprovedTaAmount?.toFixed(0)
                : adminExpenseSelectedRow?.adminApprovedTaAmount?.toFixed(0)}
            </Typography>
          </div>
          <div style={styles.card}>
            <Typography sx={{fontSize: 8}} color="primary">
              {strings.moduleSpecificUtilities.miscApproved}
            </Typography>
            <Typography sx={{fontSize: 16}} color="text.secondary">
              ₹
              {footer?.adminApprovedMiscAmount
                ? footer?.adminApprovedMiscAmount?.toFixed(0)
                : adminExpenseSelectedRow?.adminApprovedMiscAmount?.toFixed(0)}
            </Typography>
          </div>
          <div style={styles.card}>
            <Typography sx={{fontSize: 8}} color="primary">
              {strings.moduleSpecificUtilities.unlockDeduction}
            </Typography>
            {isUnlockDeductionLoading ? (
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={20} color="primary" />
              </Box>
            ) : (
              <Typography sx={{fontSize: 16}} color="text.secondary">
                {unlockDeduction?.penaltyAmount > 0 ? '-' : null}₹
                {unlockDeduction?.penaltyAmount}
              </Typography>
            )}
          </div>
        </Box>
        <Divider sx={{padding: '5px'}} />
        <Box sx={{...styles.approveDialogContent, mb: 1, mt: 2}}>
          <div style={{width: 'auto'}}>
            <Typography sx={{fontSize: 20}} color="primary">
              ₹ {adminExpenseSelectedRow?.totalSystemAmount?.toFixed(0)}
            </Typography>
            <Typography sx={{fontSize: 10}} color="text.secondary">
              {strings.moduleSpecificUtilities.systemGeneratedAmount}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{width: 'auto', paddingLeft: 10}}>
            {isUnlockDeductionLoading ? (
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={20} color="primary" />
              </Box>
            ) : (
              <Typography sx={{fontSize: 20}} color="primary">
                ₹{' '}
                {footer?.adminApprovedTotalAmount?.toFixed(0) -
                  Math.abs(unlockDeduction?.penaltyAmount)}
              </Typography>
            )}
            <Typography sx={{fontSize: 10}} color="text.secondary">
              {strings.moduleSpecificUtilities.adminApprovedAmount}
            </Typography>
          </div>
        </Box>
      </DialogContent>
      <div style={{display: 'flex'}}>
        <Checkbox
          color="primary"
          style={{color: 'primary', paddingLeft: 15}}
          checked={isCheckBoxEnabled}
          disabled={isUnlockDeductionLoading}
          onChange={handleCheckBoxChange}
          inputProps={{'aria-label': 'controlled'}}
        />{' '}
        <Typography
          sx={{fontSize: 12, alignSelf: 'center'}}
          color="text.secondary">
          {strings.moduleSpecificUtilities.certifyByMe}
        </Typography>
      </div>
      <DialogActions>
        <Box sx={styles.approveDialogActions}>
          <Button
            variant="contained"
            sx={popUpStyle.resetButton}
            onClick={handleApproveClose}>
            {strings.cancel}
          </Button>

          <Button
            variant="contained"
            sx={popUpStyle.saveButton}
            onClick={confirmExpense}
            disabled={!isCheckBoxEnabled}>
            {strings.approve}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
